<template>
  <div>
    <v-container>
      <v-alert v-if="dealerNotExists" text prominent type="error" icon="mdi-cloud-alert" class="mb-5">
        El código del distribuidor introducido es invalido
      </v-alert>
      <v-row>
        <v-col sm="12" md="5" class="text-left">
          <h3 class="mb-5">Ahora, configura tu <span class="primary--text">nuevo local</span> </h3>
          <p class="text-left">
            Un Local corresponde a cada uno de los
            establecimientos que quieres gestionar.
            Por ejemplo “Los Maños” o “Pizzería
            Vesubio”.

          </p>
          <p class="mt-5">
            Si ya tienes un Distribuidor, puedes
            indicarnos su código aquí:
          </p>

          <v-text-field v-model="dealer_id" label="Código (opcional)">
          </v-text-field>
        </v-col>
        <v-col sm="12" md="7">
          <v-form>
            <v-row dense>
              <v-col sm="12">
                <v-select
                    v-model="license"
                    :items="licensesItems"
                    label="Licencia"
                    data-vv-name="select"
                    :error-messages="licenseErrors"
                    required
                ></v-select>
              </v-col>
              <v-col sm="12">
                <v-text-field :error-messages="nameErrors"
                              v-model="name"
                              placeholder=" "
                              label="Nombre del nuevo local" required>
                </v-text-field>
              </v-col>

              <v-col sm="12" md="6">
                <v-text-field :error-messages="phoneErrors"
                              v-model="phone"
                              placeholder=" "
                              :maxlength="12"
                              label="Teléfono" required>
                </v-text-field>
              </v-col>

              <v-col sm="12" md="6">
                <v-text-field :error-messages="enterpriseErrors"
                              v-model="enterprise"
                              placeholder=" "
                              label="Empresa" required>
                </v-text-field>
              </v-col>

              <v-col sm="12" md="6">
                <v-text-field :error-messages="vatIdErrors"
                              v-model="vat_id"
                              placeholder=" "
                              :maxlength="40"
                              label="NIF" required>
                </v-text-field>
              </v-col>
              <v-col sm="6">
                <v-text-field :error-messages="emailErrors"
                              v-model="email"
                              placeholder=" "
                              :rules="[
                                  $validations.isEmail('')
                              ]"
                              :maxlength="40"
                              label="Email" required>
                </v-text-field>
              </v-col>
              <v-col sm="6">
                <v-text-field :error-messages="checkoutEmailsErrors"
                              v-model="checkouts_email"
                              placeholder=" "
                              :rules="[
                                  $validations.isEmail('')
                              ]"
                              :maxlength="40"
                              label="Enviar cierre de caja al email" required>
                </v-text-field>
              </v-col>
              <v-col sm="6">
                <v-text-field v-model="website"
                              placeholder=" "
                              label="Pagina web"
                              type="text"
                              :maxlength="40"
                              required>
                </v-text-field>
              </v-col>
            </v-row>

          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-6">
      <v-row>
        <v-col cols="12" md="3">
          <v-btn @click="$emit('back')">Anterior</v-btn>
        </v-col>
        <v-col cols="12" md="3" offset="6">
          <v-btn color="primary" @click="submit">Siguiente</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'
import errorsMixin from "../../mixins/errors";
import _ from "lodash";

export default {
  name: "Local",
  mixins: [validationMixin, errorsMixin],
  data: () => ({
    name: '',
    enterprise: "",
    vat_id: "",
    phone: "",
    email: "",
    checkouts_email: "",
    website: "",
    dealer_id: "",
    licensesItems:[],
    license:null,
    dealerNotExists:false
  }),
  props: {
    value: {
      required: true
    }
  },
  validations: {
    name: {required},
    enterprise: {required},
    vat_id: {required},
    phone: {required},
    email: {required, email},
    checkouts_email: {required, email},
    license: {required},
  },
  async mounted() {
    this.name = this.value.business.name
    this.enterprise = this.value.business.enterprise
    this.vat_id = this.value.business.vat_id
    this.phone = this.value.business.phone
    this.email = this.value.business.email
    this.checkouts_email = this.value.business.checkouts_email
    this.dealer_id = this.value.business.dealer_id
    this.website = this.value.business.website

    const licenses_items = _.omit((await this.$http.get('v2/utils/get_licenses')).data,'id')
    const licenses_items_keys = Object.keys(licenses_items);
    this.licensesItems = licenses_items_keys.map(licenses_item_key => {
      return {
        value:licenses_item_key,
        text:licenses_items[licenses_item_key].name
      }
    })
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let can_next = true;
        this.dealerNotExists = false;

        if(this.dealer_id) {
          const url = `v2/wizard/check_dealer_exists?dealer_code=${encodeURIComponent(this.dealer_id)}`
          const response = await this.$http.get(url)
          this.dealerNotExists = !(can_next = response.data.isExists)
        }

        if(can_next) {
          this.value.business.name = this.name
          this.value.business.enterprise = this.enterprise
          this.value.business.vat_id = this.vat_id
          this.value.business.phone = this.phone
          this.value.business.email = this.email
          this.value.business.checkouts_email = this.checkouts_email
          this.value.business.dealer_id = this.dealer_id
          this.value.business.license_id = this.license
          this.value.business.website = this.website
          this.$emit("next")
          this.$emit("input", this.value)
        }
      }
    },
  }
}
</script>
